.card {
  box-shadow: $box-shadow;
  background: var(--card-bg, #fff);
  height: 100%;

  --box-shadow-y: 8px;
  --box-shadow-blur: 40px;
  --box-shadow-color-opacity: 0.08;

  &:not(:has(a)) {
    --card-bg: #{$card-bg};
    box-shadow: none;
  }

  &:focus-within {
    @include focus-ring-style;
  }

  @supports not selector(:focus) {
    &:focus {
      @include focus-ring-style;
    }
  }

  .stretched-link {
    text-decoration: none;

    &:focus-visible {
      outline: unset;
    }
  }

  &-title {
    color: $body-color;
    line-height: 1.2;
    font-size: var(--card-title-font-size, #{px-to-rem(20px)});
    margin-bottom: 0;

    &:has(+ p, + h3, + h4, + div > p.bodytext) {
      margin-bottom: $grid-gutter-width * 0.5;
    }

    .frame-type-theme_blogselected &,
    .related-blogs-list & {
      margin-bottom: 0;
    }
  }

  &-title a {
    font-size: var(--card-title-font-size, #{px-to-rem(20px)});
    color: $body-color;
    text-decoration: none;
    line-height: 1.2;
  }

  &-subtitle {
    color: $primary;
    font-size: var(--card-title-font-size, #{px-to-rem(18px)});
    line-height: 1.2;
    font-weight: bold;

    &:has(+ p) {
      margin-bottom: $grid-gutter-width * 0.5;
    }
  }

  &-body {
    text-wrap: var(--card-text-wrap, balance);

    :last-child:not(.btn) {
      padding-bottom: 0 !important;
    }

    .category-list {
      font-size: var(--card-category-list-font-size, #{px-to-rem(14px)});
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &-header {
    font-size: var(--card-title-font-size, #{px-to-rem(20px)});
    line-height: 1.2;
    font-weight: $font-weight-bold;
    padding: $grid-gutter-width * 0.5;

    &__icon {
      width: 40px;
      height: 40px;
    }
  }
}

.card.card-products {
  &__slider {
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 197px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 277px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 245px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 290px;
    }

    @include media-breakpoint-up(ultrawide) {
      max-width: 260px;
    }
  }
}

.card.card-news {
  figure,
  .card-news__category {
    grid-column: 1;
    grid-row: 1;
  }

  .card-news__category {
    color: __get($theme-colors, 'white');
    background-color: __get($theme-colors, 'primary');
    padding: px-to-rem(6px) $grid-gutter-width * 0.5;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 14px;
    line-height: 1.5;
    width: fit-content;
    height: fit-content;
    letter-spacing: 0.28px;
    z-index: 1;
  }
}
